<template>
  <div class="unified-social-media-report" :class="{ 'small-screen': isSmallScreen }">
    <div class="main-content" :class="{ 'expanded': !isSidebarVisible }">
      <!-- Report Content -->
      <div v-if="currentReport" class="card">
        <div class="report-header">
          <h1>{{ currentReport.title }}</h1>
          <div class="report-actions">
            <div class="dropdown">
              <button class="btn btn-primary dropbtn">Share this report <i class="fas fa-chevron-down"></i></button>
              <div class="dropdown-content" style="min-width: 100%;">
                <a @click="downloadReport('pdf')">PDF Report</a>
                <a @click="downloadReport('md')">Markdown</a>
              </div>
            </div>
            <button @click="showFullScreenModal = true" class="btn btn-primary icon-btn full-screen-btn">
              <i class="fas fa-expand"></i>
            </button>
          </div>
        </div>
        <hr class="gradient-line">
        <div v-if="renderedContent" class="markdown-content markdown-body" v-html="renderedContent" style="overflow-y: auto; max-height: 50vh;"></div>
        <p v-else>No content available</p>
      </div>
      <!-- Welcome Message -->
      <div v-else class="card welcome-message">
        <h1>Welcome to Ara</h1>
        <p>Ara is your AI-powered social media analysis tool. Here's how to get started:</p>
        <ol>
          <li>Enter a topic in the sidebar to generate a new report.</li>
          <li>Add optional concerns to focus your analysis.</li>
          <li>Click "Generate Report" to create your social media insights.</li>
        </ol>
        <p>Not sure what to search? Try these popular topics:</p>
        <div class="suggested-topics">
          <a @click="selectSuggestedTopic('Moo Deng baby hippo')" class="suggested-topic">Moo Deng baby hippo </a>
          <a @click="selectSuggestedTopic('OpenAI')" class="suggested-topic">OpenAI</a>
          <a @click="selectSuggestedTopic('2024 US Presidential Election')" class="suggested-topic">2024 US Presidential Election</a>
        </div>
        <p>Your generated reports will appear here. Happy analyzing!</p>
      </div>
    </div>

    <!-- Full Screen Modal -->
    <div v-if="showFullScreenModal || generatingPdf" class="full-screen-modal" ref="fullScreenContent">
      <div class="modal-content">
        <div class="modal-header">
          <h1>{{ currentReport.title }}</h1>
          <button @click="showFullScreenModal = false" class="icon-btn close-modal-btn">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div v-if="renderedContent" class="markdown-content markdown-body" v-html="renderedContent" style="overflow-y: auto; height: calc(100% - 60px);"></div>
        <p v-else>No content available</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { marked } from 'marked';
import { saveAs } from 'file-saver';

export default {
  name: 'TopicSearchView',
  components: {},
  props: {
    isSidebarVisible: {
      type: Boolean,
      required: true,
    },
    currentReport: {
      type: Object,
      default: null,
    },
    isSmallScreen: {
      type: Boolean,
      required: true,
    },
    posts: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['toggle-sidebar', 'suggested-topic-selected'],
  setup(props, { emit }) {
    const showFullScreenModal = ref(false);
    const generatingPdf = ref(false);
    const fullScreenContent = ref(null);

    const renderedContent = computed(() => {
      if (!props.currentReport) return null;
      try {
        return marked(props.currentReport.content);
      } catch (error) {
        console.error('Error rendering markdown:', error);
        return 'Error rendering content';
      }
    });

    const downloadReport = (format) => {
      if (!props.currentReport) return;

      if (format === 'pdf') {
        downloadPdf(props.currentReport.content);
        return;
      }

      let content, filename, mimeType;

      switch (format) {
        case 'md':
          content = props.currentReport.content;
          filename = `${props.currentReport.title}.md`;
          mimeType = 'text/markdown';
          break;
        case 'csv':
          content = `Title,Date\n${props.currentReport.title},${props.currentReport.date}`;
          filename = `${props.currentReport.title}.csv`;
          mimeType = 'text/csv';
          break;
      }

      const blob = new Blob([content], { type: mimeType });
      saveAs(blob, filename);
    };

    const selectSuggestedTopic = (topic) => {
      emit('suggested-topic-selected', topic);
    };

    async function downloadPdf(markdownContent) {
      try {
        const serverEndpoint = process.env.VUE_APP_SERVER_ENDPOINT;
        const response = await fetch(`${serverEndpoint}/generate-pdf`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            search_term: props.currentReport.title,
            markdown: markdownContent
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to generate PDF');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const searchWords = props.currentReport.title.split(' ').slice(0, 3).join('_').toLowerCase();
        const date = new Date().toISOString().split('T')[0].replace(/-/g, '');
        a.download = `${searchWords}_${date}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading PDF:', error);
      }
    }

    return {
      showFullScreenModal,
      generatingPdf,
      renderedContent,
      downloadReport,
      selectSuggestedTopic,
      fullScreenContent
    };
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap');

.unified-social-media-report {
  font-family: 'Nunito Sans', sans-serif;
  color: #333; /* var(--text-color) */
  min-height: 100vh;
}

.main-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  transition: margin-left 0.3s;
}

.main-content.expanded {
  margin-left: 0;
}

.card {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e0e0e0; /* var(--border-color) */
  padding: 20px;
  margin-bottom: 20px;
  max-height: 80vh; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.report-header h1 {
  font-size: 32px; /* Increase font size for the search term */
  font-weight: 600;
  color: #0066CC; /* Primary color */
}

.report-actions {
  display: flex;
  gap: 10px;
}

.btn {
  background-color: white;
  border: 1px solid #e0e0e0; /* var(--border-color) */
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.3s, color 0.3s;
}

.btn:hover {
  background-color: #f0f0f0;
}

.btn-primary {
  background-color: #0066CC; /* var(--primary-color) */
  color: white;
  border-color: #0066CC; /* var(--primary-color) */
}

.btn-primary:hover {
  background-color: #005cb3;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  z-index: 1;
  border-radius: 4px;
  border: 1px solid #e0e0e0; /* var(--border-color) */
}

.dropdown-content a {
  color: #333; /* var(--text-color) */
  padding: 10px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content a:hover {
  background-color: #f5f5f5;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.welcome-message {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.welcome-message h1 {
  margin-bottom: 20px;
}

.welcome-message p {
  margin-bottom: 15px;
}

.welcome-message ol, .welcome-message ul {
  text-align: left;
  margin-left: 20px;
  margin-bottom: 15px;
}

.welcome-message li {
  margin-bottom: 5px;
}

.full-screen-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  border: 1px solid #e0e0e0; /* var(--border-color) */
  width: 80%;
  height: 80%;
  overflow-y: auto; /* Enable vertical scrolling */
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-modal-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #d9534f; /* var(--danger-color) */
}

/* Markdown content styles */
.markdown-content {
  line-height: 1.6;
  color: #333;
  /* Add more styles as needed */
}

.markdown-content pre {
  background-color: #f6f8fa;
  padding: 16px;
  border-radius: 3px;
  /* Ensure pre tags are styled correctly */
}

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}

.markdown-content h1 {
  font-size: 2em;
  position: relative;
  padding-bottom: 0.3em;
}

.markdown-content h1::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, #1A5F8C, #5FBAE0, #F8A2B9); /* Updated gradient colors */
}

.markdown-content h2 {
  font-size: 1.5em;
}

.markdown-content h3 {
  font-size: 1.25em;
}

.markdown-content p {
  margin-top: 0;
  margin-bottom: 16px;
}

.markdown-content a {
  color: #0066CC; /* Replace with actual color value */
  text-decoration: none;
}

.markdown-content a:hover {
  text-decoration: underline;
}

.markdown-content ul,
.markdown-content ol {
  padding-left: 2em;
  margin-bottom: 16px;
}

.markdown-content li {
  margin-bottom: 4px;
}

.markdown-content code {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: rgba(0, 102, 204, 0.1);
  border-radius: 3px;
}

.markdown-content pre {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f6f8fa;
  border-radius: 3px;
}

.markdown-content blockquote {
  padding: 0 1em;
  color: #6b7280; /* Replace with actual color value */
  border-left: 0.25em solid #0066CC; /* Replace with actual color value */
  margin: 0 0 16px 0;
  background-color: #f6f8fa; /* Replace with actual color value */
}

.markdown-content table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 16px;
}

.markdown-content table th,
.markdown-content table td {
  padding: 6px 13px;
  border: 1px solid #e0e0e0; /* Replace with actual color value */
}

.markdown-content table th {
  font-weight: 600;
  background-color: #f6f8fa;
}

.markdown-content table tr:nth-child(2n) {
  background-color: #f6f8fa;
}

.markdown-content img {
  max-width: 100%;
  box-sizing: content-box;
}

@media (max-width: 768px) {
  .report-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .report-actions {
    margin-top: 10px;
  }

  .modal-content {
    width: 95%;
    height: 95%;
  }
}

.full-screen-btn {
  /* Match the style of the share button */
  background-color: #0066CC;
  color: white;
  border-color: #0066CC;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.3s, color 0.3s;
}

.full-screen-btn:hover {
  background-color: #005cb3;
}

.suggested-topics {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.suggested-topic {
  display: inline-block;
  padding: 8px 16px;
  background-color: transparent;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  color: #0066CC;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  transition: all 0.3s ease;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.suggested-topic:hover {
  background-color: #0066CC;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: translateY(-1px);
}

.gradient-line {
  height: 0.25em;
  margin: 24px 0;
  background: linear-gradient(90deg, #1A5F8C, #5FBAE0, #F8A2B9); /* Direct color values */
  border: 0;
}

/* Styles for posts and comments */
.posts-section {
  margin-top: 20px;
}

.post-item {
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0;
}

.post-header a {
  font-weight: bold;
  color: #0066CC;
  text-decoration: none;
}

.post-header a:hover {
  text-decoration: underline;
}

.post-header p {
  margin-top: 5px;
  color: #333;
}

.comments-section {
  margin-left: 20px;
  margin-top: 10px;
}

.comments-section h3 {
  font-size: 1em;
  margin-bottom: 5px;
}

.comment-item {
  border-top: 1px solid #f0f0f0;
  padding: 5px 0;
}

.comment-item p {
  margin: 0;
  color: #555;
}
</style>